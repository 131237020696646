(function () {
    'use strict';

    angular.module('aerosApp')
        .factory('ToolService', ToolService);

    ToolService.$inject = ['$http', '$q', 'aerosApi', 'ToolsDefinitionService'];

    function ToolService($http, $q, aerosApi, ToolsDefinitionService) {

        var format = ToolsDefinitionService.format;

        var service = {
            getToolsDefinition: getToolsDefinition,
            compareModelFieldWithPreset: compareModelFieldWithPreset,
            presetMatch: presetMatch,
            prepareToolStructure: prepareToolStructure,
            updateDefinitionWithFieldsFromModel: updateDefinitionWithFieldsFromModel,
            addToolLibrary: addToolLibrary,
            updateToolLibrary: updateToolLibrary,
            calculateDefaultWidth: calculateDefaultWidth,
            addTool: addTool,
            updateTool: updateTool
        };

        function getToolsDefinition() {
            return $http.get('/static/templates/tools/toolsDefinition.json');
        }

        function presetMatch(ToolService, toolsDefinition, toolLibraries,toolPresets) {
            // Loop though tools
            angular.forEach(toolLibraries, function(tool) {
                if (!tool.generic) {
                    var found = false;
                    if (toolPresets) {
                        // Loop though presets
                        var toolsDefinitionGroups;
                        if (tool.type=='OTDR' && toolsDefinition.OTDR) {
                            toolsDefinitionGroups = toolsDefinition.OTDR.groups ;
                        }
                        else if (tool.type=='OLTS' && toolsDefinition.OLTS) {
                            toolsDefinitionGroups = toolsDefinition.OLTS.groups ;
                        }
                        else if (tool.type=='Certification' && toolsDefinition.Certification) {
                            toolsDefinitionGroups = toolsDefinition.Certification.groups ;
                        }
                        else if (tool.type=='Inspection' && toolsDefinition.Inspection) {
                            toolsDefinitionGroups = toolsDefinition.Inspection.groups ;
                        }
                        else {
                            toolsDefinitionGroups = toolsDefinition.groups ;
                        }

                        angular.forEach(toolsDefinitionGroups, function(definitionGroup) {
                            found = false;
                            var group = definitionGroup.id;
                            // Loop though preset groups

                            var presetGroups;
                            if (tool.type=='OTDR' && toolPresets.OTDR) {
                                presetGroups = toolPresets.OTDR[definitionGroup.id];
                            }
                            else if (tool.type=='OLTS' && toolPresets.OLTS) {
                                presetGroups = toolPresets.OLTS[definitionGroup.id];
                            }
                            else if (tool.type=='Certification' && toolPresets.Certification) {
                                presetGroups = toolPresets.Certification[definitionGroup.id];
                            }
                            else if (tool.type=='Inspection' && toolPresets.Inspection) {
                                presetGroups = toolPresets.Inspection[definitionGroup.id];
                            }
                            else {
                                presetGroups = toolPresets[definitionGroup.id]
                            }
                            // Ticket 3610 - dup presets, use preset stored in the tool as a hin
                            if (presetGroups) {
                                var hintPresetName = tool[group + ".name"];
                                var hintPreset = presetGroups.find(function (pr) {
                                    return pr.name === hintPresetName;
                                });
                                if (hintPreset) {
                                    // We found hind preset with the same name
                                    // Check if it matches the values
                                    if ((!found) &&
                                        ToolService.compareModelFieldWithPreset(tool, hintPreset.fields,
                                            definitionGroup.fields, definitionGroup)) {
                                        found = true;
                                        tool[group + ".name"] = hintPreset.name;
                                    }
                                }
                            }

                            angular.forEach(presetGroups, function (preset) {
                                // We are re-using the service defined in ToolService to match presets
                                // The same method is used to match and display the preset used in a test
                                // in a drop-down list when user edits existing tool
                                if (!found) {
                                    if (tool.type=='OTDR') {
                                        if (ToolService.compareModelFieldWithPreset(tool, preset.fields,
                                            definitionGroup.fields, definitionGroup)) {
                                            found = true;
                                        }
                                    }
                                    else if (tool.type=='OLTS') {
                                        if (preset.isCustomTestLimit &&
                                            (tool.rule === preset.name)) {
                                            found = true;
                                        }
                                    }
                                    else if (tool.type=='Certification') {
                                       if ((tool.rule === preset.name) &&
                                            (tool.testLimitKey === preset.key)) {
                                           found = true;
                                       }
                                    }
                                    else if (tool.type=='Inspection') {

                                    }
                                    if (found) {
                                        tool[group + ".name"] = preset.name;
                                    }
                                }
                            });
                        }, this)
                    }
                }
            }, this) ;
        }
        function compareModelFieldWithPreset(model, preset, definition, group) {

            if (!model || !preset) {
                return false;
            }

            if (definition) {
                for (var key in definition) {
                    var fieldName = definition[key].fieldName;
                    if ((model[group.id + '.' + fieldName] || preset[fieldName]) && (model[group.id + '.' + fieldName]
                        !== preset[fieldName])) {
                        return false;
                    }
                }
            } else {
                for (key in preset) {
                    if (model[group.id + '.' + key] !== preset[key]) {
                        return false;
                    }
                }
            }

            return true;
        }

        /**
         * puts all settings groups in "group" array
         * @param rawTool
         */

        function prepareToolStructure(rawTool) {
            var groups = [];
            for (var key in rawTool) {
                if (key != "name" || key != "id") {
                    groups[key] = rawTool[key];
                }
            }

            return {
                id: rawTool.id,
                name: rawTool.name,
                groups: groups
            }
        }

        function updateDefinitionWithFieldsFromModel(definitionFields, model) {
            angular.forEach(model, function (value, key) {

                var existingFieldDefinition = false;

                angular.forEach(definitionFields, function (defValue, defKey) {
                    if (key == defValue.fieldName) {
                        existingFieldDefinition = true;
                    }
                });

                if (!existingFieldDefinition) {
                    definitionFields.push({
                        fieldName: key,
                        name: key,
                        fieldDescription: {
                            type: "string",
                            minLength: 1,
                            maxLength: 255
                        }
                    })
                }


            });
        }

        function addToolLibrary(type, orgID, toolModel) {
            switch (type) {
                case 'OTDR':
                    return aerosApi.otdrToolLibraryAPI.post(orgID, prepareToolModel(toolModel, type.toLowerCase()));
                    break;

            }
        }

        function updateToolLibrary(type, orgID, toolModel) {
            switch (type) {
                case 'OTDR':
                    return aerosApi.otdrToolLibraryAPI.update(orgID, toolModel.library.uuid, toolModel);
                    break;
            }
        }

        function calculateDefaultWidth(numColumns, actionWidth) {
            return Math.floor((1120 - actionWidth) / numColumns);
        }

        function addTool(type, orgID, projectID, toolModel) {
            return aerosApi.createProjectToolByType({
                orgId: orgID,
                projectId: projectID,
                toolType: type.toLowerCase(),
                tool: prepareToolModel(toolModel, type)
            });
        }

        function updateTool(type, orgID, projectID, toolModel, overwrite) {
            return aerosApi.updateProjectToolByType({
                orgId: orgID,
                projectId: projectID,
                toolType: type.toLowerCase(),
                toolId: toolModel.tool.uuid,
                tool: prepareToolModel(toolModel, type),
                overwrite: overwrite
            });
        }

        function addJsonPrefixes(item) {
            return _.extend(item, _.mapKeys(item, function (v, k) {
                return _.isObject(v) ? 'json'
                    + k[0].toUpperCase() + k.slice(1) : k;
            }));
        }

        function prepareToolModel(toolModel, type) {

            return format.objToArr(format.flat2nested(toolModel.tool), ToolsDefinitionService.fetch);

        }

        return service;
    }

})();
